/* Header Styles */
.header {
  background-color: rgb(82, 82, 82);
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 25px;
}

.headerTitles {
  text-align: center;
  /* Other styles for header titles */
}

.headerTitleSm {
  display: block; /* Makes the text go to a new line */
  font-size: 0.8em; /* Reduces the size of the font */
  margin-top: 0.5em; /* Adds space between the blog title and subtitle */
}

/* Navigation Styles */
.nav {
  margin-top: 20px; /* Add margin-top to separate the navigation from the title */
}

.nav-list {
  list-style-type: none;
  padding: 0;
  text-align: center; /* Center the navigation links */
}

.nav-item {
  display: inline;
  margin-right: 20px;
}

.nav-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.nav-item a:hover {
  text-decoration: underline;
}
