
.header {
  height: 70px; /* or 10vh */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Remove any 100vh height styles that might be in App.css */
.App, .App-header {
  height: auto;
}

/* Add this to your static/style.css file */

html, body {
  height: 100%; /* Ensure the full height of the page is used */
  margin: 0; /* Remove default margin */
}

#root {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  min-height: 100%; /* Ensure the root element takes up the viewport height */
}

.content {
  flex: 1; /* This will make the content area grow to take up available space */
  /* Add padding, margin, and other styles as needed */
}

.footer {
  padding: 20px;
  text-align: center;
}